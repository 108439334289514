.link {
  color: white;
}

.packImg {
  object-fit: contain;
}

.actionBtns {
  min-width: fit-content;
  display: grid;
  gap: 12px;
}

.form {
  .title {
    font-size: 50px;
  }

  .images {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-around;
    gap: 20px;

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 250px;
      background-color: rgba(255, 255, 255, .1);

      &_label {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, .2);
        cursor: pointer;

        &:hover {
          background-color: rgba(255, 255, 255, .3);
        }
      }
    }
  }

  .inputGroup {
    position: relative;
    margin-bottom: 12px;
    display: grid;
    grid-template-columns: 2fr 3fr 50px;
    gap: 12px;

    &.filterGroup {
      grid-template-columns: 2fr 3fr 50px;
    }

    .label {
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }

    .input {
      height: 56px;
    }
  }
}

.details {
  padding-top: 50px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  position: relative;

  &>* {
    width: 100%;
  }

  &_inputGroup {
    position: relative;
    margin: 12px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
    gap: 12px;

    .input {
      flex-grow: 1;
    }
  }

  .error {
    position: absolute;
    right: 0;
    bottom: 0;
    color: red;

    &_general {
      position: relative;
    }
  }

  .formControls {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    gap: 12px;
    justify-content: flex-end;

    &_btn {
      width: 90px;
      height: 45px;
      font-size: 24px;
    }
  }

  .images {
    display: flex;
    gap: 30px;
    justify-content: center;
    max-height: 250px;
  }

  .image {
    position: relative;
    width: 380px;
    height: 250px;
    background: rgba(255, 255, 255, .1);
    display: flex;
    justify-content: center;
    align-items: center;

    object-fit: contain;

    img {
      max-height: 100%;
    }

    &_btns {
      position: absolute;
      top: 55%;

      display: flex;
      flex-direction: column;
      width: fit-content;
      align-items: center;
      justify-content: center;
    }

    &_btn {
      width: fit-content;
      padding: 0;

      label {
        padding: 10px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }



  .statusSelect {
    min-width: 200px;
    text-transform: capitalize;
  }

  .filterGroup {
    margin-bottom: 24px;
    width: 100%;

    &_heading {
      margin-bottom: 12px;
      display: flex;
      gap: 12px;
      align-items: center;
    }

    &_title {
      text-align: start;
      font-size: 24px;
    }

    &_papers {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      flex-wrap: wrap;
      gap: 16px;
    }

  }

  .filterCondition {
    position: relative;
    padding: 30px;
    display: flex;
    flex-direction: column;
    text-align: start;

    &_removeBtn {
      position: absolute;
      top: 0;
      right: 0;

      cursor: pointer;
    }

    &_image {
      height: 75px;
      margin: 0 auto;
    }

    &_filter {
      text-transform: capitalize;
    }

    &.addCondition {
      align-items: center;
      justify-content: center;

      button {
        font-size: 24px;
      }
    }


  }
}

.filterModal {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 12px;

  .closeBtn {
    position: absolute;
    width: 30px;
    height: 30px;
    right: -5px;
    top: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_title {
    font-size: 32px;
  }


  .rangeInputs {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: center;

    div {
      display: flex;
      gap: 12px;
      align-items: center;
    }
  }

  .submitBtn {
    margin-top: auto;
  }
}